import {
  allFunctions,
  AppliedCorrection,
  CheckError,
  CheckResult,
  Configuration,
  ConfigurationPlacement,
  ConfiguratorRuleData,
  CorrectionResult,
  EventResult,
  id,
  Id,
  JungleGymRuleExecution,
  ModuleBlueprint,
  ModulePlacement,
  ModuleVariant,
  RuleState,
  XAttachmentPoint,
  YAttachmentPoint
} from '@ess/jg-rule-executor';
import {environment} from '@src/environments/environment';
import {Inject, Injectable, Renderer2, RendererFactory2} from "@angular/core";
import {DOCUMENT} from "@angular/common";
import {InitDataService} from "@src/app/services/data/init-data.service";
import {FailedToLoadRulesetError, RuleExecutionError, RuleExecutionNotInitializedError} from "@src/app/model/errors";
import {NotificationService} from "@src/app/services/notification/notification.service";
import {Icon} from "@src/app/library/components/icon";
import {NotificationCardType} from "@src/app/components/notification-card/notification-card.type";
import {TranslateService} from "@ngx-translate/core";
import {uniq} from 'lodash-es';
import {InvariantTranslationKeys} from "@src/app/model/invariant-translation-keys";
import {LoggingService} from "@src/app/services/logging/logging.service";
import {DebugService} from "@src/app/services/debug/debug.service";

@Injectable({
  providedIn: 'root'
})
/**
 * Wraps rule execution in a nice angular sauce.
 */
export class RuleService {
  private _ruleExecution: JungleGymRuleExecution | undefined;
  private _renderer2: Renderer2;
  private _elementName: string = "script";
  private _rulesUrl: string = "/graphql/scripts/rules.js";
  private _scriptType: string = 'text/javascript';
  private _maxFixTries: number = 3;
  private _retryFailMsgKey: string = "app.notifications.rules.error.max_fix_tries_exceeded";

  constructor(
    rendererFactory: RendererFactory2,
    @Inject(DOCUMENT) private _document,
    private _initDataService: InitDataService,
    private _notificationService: NotificationService,
    private _translateService: TranslateService,
    private _loggingService: LoggingService,
    private _debugService: DebugService
  ) {
    this._renderer2 = rendererFactory.createRenderer(null, null);
  }

  /**
   * Returns a promise that completes when rules are loaded
   */
  public initialize(): Promise<void> {
    // we dynamically inject a script tag, referring to the rule set on the server.
    return new Promise((resolve, err) => {
      const scriptTag = this._renderer2.createElement(this._elementName);
      scriptTag.type = this._scriptType;
      scriptTag.src = environment.backendUrl + this._rulesUrl;
      scriptTag.onload = () => {
        this._ruleExecution = new JungleGymRuleExecution();
        resolve();
      };
      scriptTag.onerror = () => {
        err(new FailedToLoadRulesetError());
      };
      this._renderer2.appendChild(document.body, scriptTag);
    });
  }

  /**
   * Validate the configuration with the rule engine.
   */
  public validateWithRuleEngine(configuration: Configuration, ruleState?: RuleState): CheckResult {
    if (!this._initDataService.noRulesAndValidation) {
      if (!ruleState) {
        ruleState = new RuleState(
          this._initDataService.availableFatModuleVariants,
          this._initDataService.publishedFatModuleVariants,
          configuration.configurationPlacement,
          this._ruleExecution,
          {
            unavailableFatVariants: this._initDataService.unavailableFatModuleVariantIds
          }
        )
      }
      const result: CheckResult = this._validate(configuration.configurationPlacement, ruleState);

      // If something went wrong, print to the console depending on what errors were returned
      if (result.type !== 'success') {
        result.errors.filter(err => {
          if (err.type === "BlueprintInvariantCheckError") {
            if (err.error) {
              this._loggingService.error(err.error);
            }
          } else if (err.type === "ModuleVariantNotFound") {
            this._loggingService.error(new RuleExecutionError(`Module variant with id ${err.ofVariant} not found`));
          } else if (!this._canAttemptCorrect([err])) {
            // If the error is not correctable, don't worry about it here
          } else if (err.error) {
            this._loggingService.error(new RuleExecutionError(`An unknown failure occurred while validating the configuration with the rule engine`));
          }
        });
      }
      return result;
    } else {
      // In case we do not want to validate, just return OK
      return {type: "success"};
    }
  }

  /**
   * Creates a module placement in the configuration by outsourcing this to the rule executor
   */
  public createPlacement(
    onModulePlacementId: Id<ModulePlacement>,
    xAttachmentId: Id<XAttachmentPoint>,
    variantId: Id<ModuleVariant>,
    configurationPlacement: ConfigurationPlacement,
    configuratorData: ConfiguratorRuleData,
    directCall: boolean
  ): EventResult {
    const createResult = this._ruleExecution.createModulePlacement(
      onModulePlacementId,
      xAttachmentId,
      variantId,
      configurationPlacement,
      this._initDataService.availableFatModuleVariants,
      this._initDataService.inConfigAndPublishedModuleVariants,
      configuratorData,
      this._initDataService.unavailableFatModuleVariantIds,
      this._initDataService.catalog.categories.map(c => c.moduleCategories).flat(),
      undefined,
      directCall && this._debugService.debug.debug
    );
    if (directCall && this._debugService.debug.debug) {
      this._debugService.latestDebugData = createResult.ruleState.debugData.calls;
    }
    return createResult;
  }

  /**
   * Change a module placement in the configuration by outsourcing this to the rule executor
   */
  public changePlacement(
    oldModulePlacementId: Id<ModulePlacement>,
    configurationPlacement: ConfigurationPlacement,
    configuratorData: ConfiguratorRuleData,
    newVariantId?: Id<ModuleVariant>,
  ): EventResult {
    const changeResult = this._ruleExecution.changeModulePlacement(
      oldModulePlacementId,
      configurationPlacement,
      this._initDataService.availableFatModuleVariants,
      this._initDataService.inConfigAndPublishedModuleVariants,
      configuratorData,
      id(this._initDataService.currentScope),
      this._initDataService.catalog.categories.map(c => c.moduleCategories).flat(),
      this._initDataService.unavailableFatModuleVariantIds,
      newVariantId,
      undefined,
      this._debugService.debug.debug
    );
    if (this._debugService.debug.debug) {
      this._debugService.latestDebugData = changeResult.ruleState.debugData.calls;
    }
    return changeResult;
  }

  /**
   * Removes a module placement in the configuration by outsourcing this to the rule executor
   */
  public removePlacement(
    parentPlacementId: Id<ModulePlacement>,
    xAttachmentId: Id<XAttachmentPoint>,
    configurationPlacement: ConfigurationPlacement,
    configuratorData: ConfiguratorRuleData
  ): EventResult {
    const removeResult = this._ruleExecution.deleteModuleAttachment(
      parentPlacementId,
      xAttachmentId,
      configurationPlacement,
      this._initDataService.availableFatModuleVariants,
      this._initDataService.inConfigAndPublishedModuleVariants,
      configuratorData,
      this._initDataService.catalog.categories.map(c => c.moduleCategories).flat(),
      this._initDataService.unavailableFatModuleVariantIds,
      undefined,
      this._debugService.debug.debug
    );
    if (this._debugService.debug.debug) {
      this._debugService.latestDebugData = removeResult.ruleState.debugData.calls;
    }
    return removeResult;
  }

  /**
   * Removes a main module placement in the configuration by outsourcing this to the rule executor
   */
  public removeMainModulePlacement(
    placementId: Id<ModulePlacement>,
    configurationPlacement: ConfigurationPlacement,
    configuratorData: ConfiguratorRuleData,
    directCall: boolean = false
  ): EventResult {
    const removeResult = this._ruleExecution.deleteMainModule(
      placementId,
      configurationPlacement,
      this._initDataService.availableFatModuleVariants,
      this._initDataService.inConfigAndPublishedModuleVariants,
      configuratorData,
      this._initDataService.catalog.categories.map(c => c.moduleCategories).flat(),
      this._initDataService.unavailableFatModuleVariantIds,
      undefined,
      directCall && this._debugService.debug.debug
    );
    if (directCall && this._debugService.debug.debug) {
      this._debugService.latestDebugData = removeResult.ruleState.debugData.calls;
    }
    return removeResult;
  }

  /**
   * For a given blueprint to be placed onto a given onModule, return the list of FREE XAttachmentPoints that it would fit onto.
   */
  public checkFitsAndFree(
    configurationPlacement: ConfigurationPlacement,
    onModuleId: Id<ModulePlacement>,
    blueprint: ModuleBlueprint,
  ): { x: XAttachmentPoint, y: YAttachmentPoint }[] {
    const state = new RuleState(
      this._initDataService.availableFatModuleVariants,
      this._initDataService.inConfigAndPublishedModuleVariants,
      configurationPlacement.clone(),
      this._ruleExecution,
      {
        unavailableFatVariants: this._initDataService.unavailableFatModuleVariantIds
      }
    );
    return allFunctions.freeXForBlueprint(configurationPlacement.placement(onModuleId), blueprint, state);
  }

  /**
   * For a given blueprint to be placed onto a given onModule, return the list of ALL XAttachmentPoints
   * (including those currently in use) that it would fit onto.
   */
  public checkFits(
    configurationPlacement: ConfigurationPlacement,
    onModuleId: Id<ModulePlacement>,
    blueprint: ModuleBlueprint,
  ): { x: XAttachmentPoint, y: YAttachmentPoint }[] {
    const state = new RuleState(
      this._initDataService.availableFatModuleVariants,
      this._initDataService.inConfigAndPublishedModuleVariants,
      configurationPlacement.clone(),
      this._ruleExecution,
      {
        unavailableFatVariants: this._initDataService.unavailableFatModuleVariantIds
      }
    );
    return allFunctions.xForBlueprint(configurationPlacement.placement(onModuleId), blueprint, state);
  }

  /**
   * Attempts to correct a configuration given a CheckResult from a previous Validate step
   * Will also validate the config after fixing, and if the result is again an invalid config, it will attempt to
   * correct again up to a total of _maxFixTries times. If no fix can be made, it will return the original
   * configurationPlacement, and will show a notification (if showNotifications is true)
   *
   * Actual loop is done in doValidateFixLoop, validation checks/handling of result is done here.
   *
   * @param configuration the configuration
   * @param errors the errors found in the validate step
   * @param configuratorData the configurator input for the rule executor
   * @param showNotifications whether to trigger notifications
   * @param ruleState optional rule state
   */
  public correctComplete(
    configuration: ConfigurationPlacement,
    errors: CheckError[],
    configuratorData: ConfiguratorRuleData,
    showNotifications: boolean = true,
    ruleState?: RuleState
  ): { placement: ConfigurationPlacement, successful: boolean } {
    let success: boolean = true;

    if (!this._ruleExecution) {
      this._loggingService.error(new RuleExecutionNotInitializedError());
      return {successful: false, placement: undefined};
    }

    if (errors.length === 0) {
      return {placement: configuration, successful: success};
    }

    const fixLoopResult = this._doValidateFixLoop(configuration, errors, configuratorData, ruleState);
    // sometimes, an invariant might be corrected multiple times. only show message once.
    const corrections: string = uniq(fixLoopResult.appliedCorrections
      .map(corr => {
        if (!(corr.invariant.translationKeys instanceof Map)) {
          // note: since the rules are transferred in a (plain) js object, this conversion is required
          corr.invariant.translationKeys = new Map(Object.entries<string>(corr.invariant.translationKeys));
        }
        const corrKey = corr.invariant.translationKeys.get(InvariantTranslationKeys.correction);
        const text = this._translateService.instant(corrKey ? corrKey : "app.notifications.rules.correction.general");
        return text.trim() !== "" ? `- ${text}` : null;
        // return `- ${this._translateService.instant(corrKey ? corrKey : "app.notifications.rules.correction.general")}`;
      }))
      .filter(y => y !== null)
      .join("\n");

    if (fixLoopResult.failed) {
      success = false;
      if (showNotifications) {
        let failText = this._translateService.instant(fixLoopResult.failReasons ?
          'app.notifications.rules.error.failed_to_fix_reason' : 'app.notifications.rules.error.failed_to_fix_no_reason');

        const failReasons = fixLoopResult.failReasons ? uniq(fixLoopResult.failReasons.map((r) => {
          const text = this._translateService.instant(r);
          return text && text.trim() !== "" ? `- ${text}` : null;
        })).filter(y => y !== null) : [];

        // const saveText = this._translateService.instant('app.notifications.rules.error.warn_no_save');
        // const partialFailText = this._translateService.instant("app.notifications.rules.error.partial_fix");

        if (failReasons.length > 0 || corrections.trim() !== ""/* && failText.trim() !== "" && saveText.trim() !== ""*/) {
          failText += failReasons.length > 0 ? "\n" + failReasons.join("\n") : "";
          failText += "\n\n" + this._translateService.instant('app.notifications.rules.error.warn_no_save');
          failText += corrections.trim() !== "" ? "\n\n" + this._translateService.instant("app.notifications.rules.error.partial_fix") : "";
          failText += corrections.trim() !== "" ? "\n" + corrections : "";

          this._notificationService.showNotification(
            failText,
            Icon.warn,
            NotificationCardType.warning,
          );
        }
      }
    } else {
      if (showNotifications) {
        if (corrections.trim() !== "") {
          const successText = this._translateService.instant('app.notifications.rules.fix_success') +
            "\n\n" + this._translateService.instant('app.notifications.rules.corrections_applied') +
            "\n" + corrections;

          this._notificationService.showNotification(successText, Icon.tool, NotificationCardType.info);
        }
      }
    }

    return {placement: fixLoopResult.fixedConfig, successful: success};
  }

  /**
   * Validates a configuration.
   *
   * @param configuration the configuration
   * @param ruleState the rule state (optional)
   */
  private _validate(configuration: ConfigurationPlacement, ruleState: RuleState): CheckResult {
    if (!this._ruleExecution) {
      this._loggingService.error(new RuleExecutionNotInitializedError());
      return {type: 'failed', errors: []};
    }

    const publishedModuleVariantsByVariantId = new Map(
      this._initDataService.inConfigAndPublishedModuleVariants.map(fv => [fv.variant.id, fv]),
    );

    return this._ruleExecution.check(
      configuration.clone(),
      publishedModuleVariantsByVariantId,
      id(this._initDataService.currentScope),
      this._initDataService.unavailableFatModuleVariantIds,
      ruleState
    );
  }

  /**
   * @return in order:
   *    1) resulting ConfigurationPlacement (either the same as the original or corrected)
   *    2) corrections that were applied, if any
   *    3) whether we failed to correct (and should thus inform the user)
   *    4) fail reason, if any
   * @private
   */
  private _doValidateFixLoop(
    configuration: ConfigurationPlacement,
    errors: CheckError[],
    configuratorData: ConfiguratorRuleData,
    ruleState?: RuleState
  ): {
    fixedConfig: ConfigurationPlacement,
    appliedCorrections: AppliedCorrection[],
    failed: boolean,
    failReasons: string[]
  } {
    let tries = 0;
    let appliedSomeFixes = false;
    let failed = false;
    let failReasons: string[];
    let fixedConfig: ConfigurationPlacement = configuration.clone();
    const appliedCorrections: AppliedCorrection[] = [];
    let tempErrors: CheckError[] = errors;
    let fixedConfigValidation: CheckResult;

    // while not failed, not applied some fixes and not exceeded maxFixTries, try to fix config
    while (!failed && !appliedSomeFixes && tries < this._maxFixTries) {
      tries++;
      // do the check
      const correctResult: CorrectionResult = this._ruleExecution.correct(
        fixedConfig,
        this._initDataService.availableFatModuleVariants,
        this._initDataService.inConfigAndPublishedModuleVariants,
        tempErrors,
        configuratorData,
        this._initDataService.unavailableFatModuleVariantIds,
        ruleState
      );

      // handle result
      if (correctResult.type === "CorrectionSuccess") {
        // if correction success, set fixedConfig and validate new config
        fixedConfig = correctResult.result;

        // for all corrections actually applied, add to appliedCorrections array
        correctResult.appliedCorrections.forEach(corr => {
          if (corr.type === "AppliedCorrection") {
            appliedCorrections.push(corr);
          }
        });

        if (!ruleState) {
          ruleState = new RuleState(
            this._initDataService.availableFatModuleVariants,
            this._initDataService.publishedFatModuleVariants,
            configuration,
            this._ruleExecution,
            {
              unavailableFatVariants: this._initDataService.unavailableFatModuleVariantIds
            }
          )
        }
        // Now validate the fixed configuration
        fixedConfigValidation = this._validate(fixedConfig, ruleState);

        if (fixedConfigValidation.type === "success") {
          // success = done
          appliedSomeFixes = true;
        } else if (!this._canAttemptCorrect(fixedConfigValidation.errors)) {
          // if all the errors are not fixable, we need to exit and inform
          // fixing everything failed, but
          tempErrors = fixedConfigValidation.errors;
          failReasons = fixedConfigValidation.errors.map(x => this._getFailReason(x));
          // We did fail to fix, but there could be applied corrections
          failed = true;
          appliedSomeFixes = appliedCorrections.length > 0;
        } else if (fixedConfigValidation.errors) {
          // if unsuccessful validation, we try to correct again with new errors
          tempErrors = fixedConfigValidation.errors;
        }
      } else {
        // if validation fails, exit loop with failed state. This means there were errors without corrections that failed.
        failed = true;
        failReasons = tempErrors
          .filter((ce: CheckError) =>
            !((ce.type === 'BlueprintInvariantCheckError' || ce.type === 'EnvironmentInvariantCheckError') && ce.invariant.correction)
          ).map(ce => this._getFailReason(ce));
      }
    }

    if (tries >= this._maxFixTries) {
      failed = true;
      failReasons = [this._retryFailMsgKey];
    }

    return {
      fixedConfig: appliedSomeFixes ? fixedConfig : configuration,
      appliedCorrections,
      failed,
      failReasons
    };
  }

  /**
   * Returns whether the rule executor can attempt to fix any of the errors.
   */
  private _canAttemptCorrect(errors: CheckError[]): boolean {
    return this._ruleExecution.canAttemptCorrect(errors);
  }

  /**
   * Gets the fail reason (name of the error is present) for every check error
   */
  private _getFailReason(error: CheckError): string {
    switch (error.type) {
      case "BlueprintInvariantCheckError":
      case "BlueprintInvariantCorrectionError":
      case "EnvironmentInvariantCheckError":
      case "EnvironmentInvariantCorrectionError": {
        if (!(error.invariant.translationKeys instanceof Map)) {
          // note: since the rules are transferred in a (plain) js object, this conversion is required
          error.invariant.translationKeys = new Map(Object.entries<string>(error.invariant.translationKeys));
        }
        const errKey = error.invariant.translationKeys.get(InvariantTranslationKeys.error);
        if (errKey) {
          return errKey;
        }
        return "app.notifications.rules.error.general";
      }
      case "ModuleVariantNotFound":
        return "variant_not_found";
      default:
        return "unknown";
    }
  }
}
